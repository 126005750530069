import React from "react";


function SiderLeft() {
  



  const scrollToContact = () => {
    const contactSection = document.getElementById("contact-section");

    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };
  return (
    <div className="fixed left-0 bottom-0 px-10 sm:static ">
      <div className="flex flex-col items-center">
        <div className="flex flex-col gap-3  sm:flex-row">
          <a href="https://github.com/amtenu">
            <i class="ri-github-line  text-gray-500 "></i>
          </a>
          <a href="https://www.linkedin.com/in/amanuel-nigussie/">
            <i class="ri-linkedin-box-fill text-gray-500 "></i>
          </a>
          <a href="mailto:amannov21@gmail.com" aria-label="Send Email">
            <i className="ri-mail-add-line text-gray-500" onClick={scrollToContact}></i>
          </a>
          <a href="https://twitter.com/amannugussie" aria-label="X">
          <i class="ri-twitter-x-line  text-gray-500" aria-label="Call"/>
          </a>
        
           <a href="tel:+14037105253" aria-label="Call">
            <i className="ri-smartphone-line text-gray-500" onClick={scrollToContact}></i>
          </a>
        </div>
        <div className="w-[1px] h-32 bg-[#125f63] sm:hidden"></div>
      </div>
    </div>
  );
}

export default SiderLeft;
