import java from "../resources/assets/java.png"
import DevOps from "../resources/assets/DevOps.png"
import AWS from "../resources/assets/AWS.png"
export const courses = 
[
  {
    title: `DevOps<p> Digital Lync </p>`,
    image: [DevOps],
    link: "https://www.inceptionu.com/full-stack-developer-program",
    description: `
   
<div style={{ color: 'white' }}>


    <p style={{ marginBottom: '10px' }}>
        At Digital Lync, my journey in DevOps training was a comprehensive exploration of integrating development, operations, automation, and continuous delivery collaboration.</br></br> Beginning with foundational and advanced Linux, I acquired a solid understanding of operating systems crucial for DevOps practices. Next, I delved into Git and GitHub, mastering version control techniques essential for managing codebases efficiently.
    </p>
    <p style={{ marginBottom: '10px' }}>
        With the groundwork laid, I progressed to conducting static code analysis using SonarQube and GitHub, ensuring code quality and adherence to best practices.</br></br> Subsequently, I ventured into deploying applications on Nginx, leveraging Nexus artifact management to streamline artifact storage across development branches on AWS EC2 instances.</br>
    </p>
    <p style={{ marginBottom: '10px' }}>
       <br/> Embracing the world of containerization, I immersed myself in Docker, learning to containerize frontend, backend, and database components seamlessly. Further enriching my skill set, I explored Kubernetes, harnessing its capabilities in service discovery, load balancing, automated rollouts, and self-healing containers.I  successfully deploying Dockerized applications on Kubernetes (Minikube) marked a significant milestone in my journey.
    </p>
    <p style={{ marginBottom: '10px' }}>
      </br>  Practical exposure to Ansible empowered me to automate IT operations efficiently, simplifying system configuration, software deployment, and workflow orchestration.</br> Terraform provided a powerful toolset for infrastructure as code, enabling the seamless management of diverse resources across multiple cloud providers like AWS and Azure.</br></br>
    </p>
    <p style={{ marginBottom: '10px' }}>
        Additionally, I've gained valuable insights into DevOps practices through my learning of Prometheus and Grafana during a project. Together, I have learned they teams to gain valuable insights into the health and performance of their systems, detect and respond to issues promptly, and ensure the reliability and availability of their applications and infrastructure.</br></br>
    </p>
    <p style={{ marginBottom: '10px' }}>
        Finally, Jenkins emerged as a cornerstone in implementing continuous integration and continuous delivery (CI/CD) pipelines, offering flexibility, scalability, and reliability.</br> Through hands-on experience, I designed and implemented robust CI/CD pipelines, streamlining the build, test, and deployment processes for applications, thus realizing the full potential of DevOps practices.
    </p>



</div>

   
     `
    
  },
  {
    title: `AWS<p> Digital Lync </p>`,
    image: [AWS],
    link: "https://www.inceptionu.com/full-stack-developer-program",
    description: `
   
    <div style={{ color: 'white' }}>
    <p style={{ marginBottom: '10px' }}>
        At Digital Lync, my journey in AWS training was a comprehensive exploration of cloud computation, Regions and zones, VPC, and the different file systems (EFS, EBS, Snapshots).</br></br> Afterwards, I mastered IAM users, roles, and groups. I acquired essential skills for managing access to AWS resources securely and efficiently. These skills are foundational for building secure and compliant AWS environments and are critical for anyone working with AWS services and infrastructure.
        </br></br>
        </p>
    <p style={{ marginBottom: '10px' }}>
        In my journey of learning CloudFormation, I delved into its capabilities to define and manage AWS infrastructure using declarative templates. With CloudFormation, I learned how to describe the desired state of various AWS resources such as EC2 instances, VPCs, subnets, EBS, S3, and route tables.</br></br>
    </p>
    <p style={{ marginBottom: '10px' }}>
        My learning journey with CloudWatch Metrics provided me with a solid foundation in monitoring key performance indicators and implementing proactive measures to maintain the stability and efficiency of my AWS environment.</br></br>
    </p>
    <p style={{ marginBottom: '10px' }}>
        Route 53 encompassed domain registration, DNS management, and advanced traffic routing capabilities, equipping me with the knowledge and skills to effectively manage and optimize DNS infrastructure for web applications and services deployed on the AWS cloud platform.</br></br>
    </p>
    <p style={{ marginBottom: '10px' }}>
        Additionally, I've gained valuable insights into Lambda functions, Amazon API Gateway, Amazon RDS, Amazon DynamoDB, Amazon SNS, Amazon SES, and many more.
    </p>

   
     `
    
  },
  
  
  
  {
    title: `Full Stack Developer<p> InceptionU ,Calgary Alberta</p>`,
    image: "https://miro.medium.com/max/750/1*-hL6ZeXl_Kt0O-b82UQo9A.png",
    link: "https://www.inceptionu.com/full-stack-developer-program",
    description: `At InceptionU in Calgary, Alberta, I acquired invaluable skills and a growth mindset that have positioned me for success in today’s dynamic digital landscape.</br></br> Their immersive programs not only honed my technical proficiency in full-stack development, including the MERN Stack (MongoDB, Express.js, React, and Node.js),Mobile Development, QA testing,Agile Development, Firebase and many more , but also fostered essential non-technical abilities like design thinking, critical analysis, and entrepreneurial mindset.</br> These competencies empower me to tackle challenges creatively and adapt swiftly to the ever-evolving technology industry.</br></br> I contributed to building an impressive mobile game with extensive features and an admin web app—an achievement that exemplified my capacity to apply my skills effectively and create impactful products.</p> `
    
  },
  {
    title: `Java Programming Masterclass<p>Udemy,Tim Buchalka </p>`,
    image:
      [java],
    link: "/",
    description: `

    Throughout my journey of learning Java, I've dedicated significant time to mastering its core concepts and embracing industry best practices. This involved delving deep into foundational topics to ensure a solid understanding of Java's key components and features. Central to this endeavor was my exploration of Java collections, where I gained proficiency in manipulating data structures and leveraging generics to write flexible and type-safe code.</br></br> Additionally, I've extensively studied Java streams, adopting functional programming paradigms to process data efficiently and write expressive code.</br>
    Concurrency has been a crucial aspect of my Java learning journey, where I've immersed myself in multithreading concepts and thread synchronization mechanisms. I've implemented concurrency solutions using Java's advanced utilities, such as Executors and Concurrent Collections, to develop scalable and responsive applications.</br></br> I've also gained hands-on experience with Java Database Connectivity (JDBC), mastering techniques for interacting with databases and executing SQL queries with precision.
    Moreover, my Java learning journey has encompassed advanced topics like network programming, debugging, and unit testing.</br> I've delved into socket programming, mastering protocols like TCP/IP and UDP to build robust client-server applications. Through rigorous debugging practices and the adoption of test-driven development (TDD) with frameworks like JUnit, I've ensured code quality, reliability, and maintainability.</br></br> Overall, my journey reflects a commitment to continuous learning and a passion for mastering Java's intricacies to contribute effectively to Java-based projects`,
  },
  
  
 
  
  
];
