import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { courses } from "../../resources/courses";
import HtmlParser from "react-html-parser";

function Courses() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div>
      <SectionTitle title="Recent Courses" />
      <div className="flex py-10 gap-20 sm:flex-col ">
        <div className="flex flex-col gap-10 border-l-2 border-[#135e4c82] w-1/3 sm:flex-row sm:overflow-x-scroll sm:w-full ">
          {courses.map((course, index) => (
            <div
              onClick={() => setSelectedIndex(index)}
              className="cursor-pointer"
            >
              <h1
                className={`text-xl px-5 ${
                  selectedIndex === index
                    ? "text-tertiary border-tertiary border-l-4 -ml-[3px] bg-[#eed13f5f] py-3"
                    : "text-white"
                }`}
              >
                {HtmlParser(course.title)}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center gap-10">
         
        <div className="flex flex-col gap-5">
          <h1 className="text-secondary text-xl ">
            {HtmlParser(courses[selectedIndex].title)}
          </h1>
          <p className="text-white flex flex-row">{HtmlParser(courses[selectedIndex].description)}</p>
          
          

          <div >
          
            {/* <h1 className="text-secondary mb-2 ">Technologies </h1> */}
            {/* {courses[selectedIndex].technologies.map((technology, index) => (
              <span key={index} className="text-white mr-3 mb-2">
                {technology}
              </span>
            ))} */}
          </div>
           
        </div>
        <img src={courses[selectedIndex].image} alt="" className="h-52 w-80"/>      
        </div>

      </div>
    </div>
  );
}

export default Courses;
