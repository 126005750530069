import React, { useEffect, useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { projects } from "../../resources/projects";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import HtmlParser from "react-html-parser";

function Projects() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  // useEffect(() => {
  //   const intervalId = setInterval(() => {
  //     setSelectedIndex((prevIndex) => (prevIndex + 1) % projects.length);
  //   }, 3000);

  //   return () => clearInterval(intervalId);
  // }, [selectedIndex]);

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  return (
    <div>
      <SectionTitle title="Sample Projects" />
      <div className="flex py-10 gap-20 sm:flex-col ">
        <div className="flex flex-col gap-10 border-l-2 border-[#135e4c82] w-1/3 sm:flex-row sm:overflow-x-scroll sm:w-full ">
          {projects.map((project, index) => (
            <div
              onClick={() => setSelectedIndex(index)}
              className="cursor-pointer"
            >
              <h1
                className={`text-xl px-5 ${
                  selectedIndex === index
                    ? "text-tertiary border-tertiary border-l-4 -ml-[3px] bg-[#eed13f5f] py-3"
                    : "text-white"
                }`}
              >
                {project.title}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col items-center justify-center gap-10">
          <Carousel
            showThumbs={false}
            dynamicHeight={false}
            style={{ width: "100px", height: "100px" }} // Adjust the height as needed
          >
            {projects[selectedIndex]?.image?.map((image, index) => (
              <div key={index} style={{ width: "100%", height: "100%" }}>
                <img
                  src={image}
                  alt={`Image ${index + 1}`}
                  onClick={() => handleImageClick(index)}
                  style={{
                    cursor: "pointer",
                    width: "50%",
                    height: "100%",
                    objectFit:"inherit"
                  }}
                />
              </div>
            ))}
          </Carousel>
          {selectedImageIndex !== null && (
            <div className="modal">
              <div className="modal-content">
                <span
                  className="close"
                  onClick={() => setSelectedImageIndex(null)}
                >
                  &times;
                </span>
                <img
                  src={projects[selectedIndex].image[selectedImageIndex]}
                  alt={`Image ${selectedImageIndex + 1}`}
                />
              </div>
            </div>
          )}

          {/* <Carousel
              showThumbs={false}
              dynamicHeight={false}
                  
            >
              {projects[selectedIndex]?.image?.map((image, index) => (
                <div key={index}>
                  <img
                    src={image}
                    alt={`Image ${index + 1}`}
                    style={{ width: "100%", height: "300px" }}
                  />
                </div>
              ))}
            </Carousel> */}
          <div className="flex flex-col gap-5">
            <h1 className="text-secondary text-xl ">
              {projects[selectedIndex].title}
            </h1>
            <p className="text-white">{HtmlParser(projects[selectedIndex].description)}</p>
            {/* <p className="text-white">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?
            </p> */}

            <div>
              <h1 className="text-secondary mb-2 ">Technologies </h1>
              {projects[selectedIndex].technologies.map((technology, index) => (
                <span key={index} className="text-white mr-3 mb-2">
                  {technology}
                </span>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Projects;
