import React from "react";
import Headers from "../../components/Header";
import About from "./About";
import Intro from "../../pages/Home/Intro";
import Experiances from "./Experiances";
import Projects from './Projects'
import Courses from "./Courses";
import Contact from "../Contact";
import Footer from "../Footer";
import SiderLeft from "./SiderLeft";

function Home() {
  return (
    <div>
      <Headers />
      <div className="bg-primary px-40 sm:px-5">
        <Intro />
        <About />
        <Experiances/>
        <Projects/>
        <Courses/>
        <Contact/>
        <Footer/>
        <SiderLeft/>

      </div>
    </div>
  );
}

export default Home;
