
import image1 from '../resources/assets/image1.png'

import image3 from  '../resources/assets/image3.png'
import image4 from  '../resources/assets/image4.png'

import image21 from "../resources/assets/image21.png";
import image22 from "../resources/assets/image22.png";
import image23 from "../resources/assets/image23.png"
import image5 from "../resources/assets/image5.png"
import image2 from "../resources/assets/image5.png"

export const projects = 
[
  {
    technologies: ["Javascript","React","Express.js","Node js","REST API", "AWS S3", "AWS SES","Google places API","Google Maps API","MongoDb","MongoDb Atlas","Bootstrap CSS" ],

    title: "myHommy",
    image: [image1,image2,image3,image4,image5],
    description:
      "I spearheaded the development of myHommy, an intuitive app revolutionizing the property search and listing experience in Calgary and beyond. Offering an extensive array of land and house listings, myHommy provides users with a seamless platform to explore properties, register accounts, favorite listings, and directly connect with agents for inquiries. Leveraging cutting-edge technologies such as JavaScript, React, Node.js, AWS services, and Google Maps integration, myHommy delivers a user-friendly interface and robust functionality, ensuring a streamlined experience for both users and listing agents."  ,
      
    link: "/",
  },
 {
    technologies: ["React", "Node js", "React Native", "React Native paper","Firebase Firestore","Firebase","Google maps Api","MUI","REST API","BlueStack","Expo" ,"Jira"],

    title: "AMONG YYC",
    image: [image21,image22,image23],
    description:`<p classname="mb-2>
    As the Team Lead for Among YYC, I played a pivotal role in driving the project's success by overseeing its development and ensuring alignment with our objectives. My responsibilities spanned various areas, including contributing to user stories, designing algorithms, and leading coding implementation.
  </p>
  <p classname="mb-2">
    I worked closely with the team to define requirements, prioritize features, and ensure that the app met the needs of our users while delivering an optimal exploration experience of downtown Calgary's historical sites and landmarks. Additionally, I took on the role of QA lead, overseeing the quality assurance process to validate the app's functionality, usability, and performance.
  </p>
  <p>
    Leveraging my expertise in software development, I led by example in coding implementation, ensuring the quality and maintainability of the codebase. Furthermore, in the absence of the Product Manager, I assumed the duties of Scrum Master, facilitating agile development processes and fostering collaboration and continuous improvement within the team through daily stand-up meetings, sprint planning sessions, and retrospectives.
  </p>
  <p>
    Through these efforts, I played a key leadership role in driving the successful development and delivery of Among YYC, enriching the exploration experience for users and showcasing the vibrant essence of downtown Calgary.
  </p>`,
    link: "/",
  },
  

  ];
