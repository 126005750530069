import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { experiances } from "../../resources/experiances";
import reactHtmlParser from "react-html-parser";

function Experiances() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  return (
    <div>
      <SectionTitle title="Experience" />
      <div className="flex py-10 gap-20 sm:flex-col ">
        <div className="flex flex-col gap-10 border-l-2 border-[#135e4c82] w-1/3 sm:flex-row sm:overflow-x-scroll sm:w-full ">
          {experiances.map((experiance, index) => (
            <div
              onClick={() => setSelectedIndex(index)}
              className="cursor-pointer"
            >
              <h1
                className={`text-xl px-5 ${
                  selectedIndex === index
                    ? "text-tertiary border-tertiary border-l-4 -ml-[3px] bg-[#eed13f5f] py-3"
                    : "text-white"
                }`}
              >
                {experiance.period}
              </h1>
            </div>
          ))}
        </div>
        <div className="flex flex-col gap-5 ">
          <h1 className="text-secondary text-xl ">
            {experiances[selectedIndex].title}
          </h1>
          <h1 className="text-tertiary text-xl ">
            {experiances[selectedIndex].company}
          </h1>
          <h1 className="text-tertiary text-xl ">
           {reactHtmlParser(experiances[selectedIndex].description)}
          </h1>
        </div>
      </div>
    </div>
  );
}

export default Experiances;
