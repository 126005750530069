import React from 'react';

function Header() {
  return (
    <div className='p-5 bg-primary flex flex-col items-start '>
      <h1 className='text-white text-4xl font-semibold mb-6'>{"<Hello World!/>"}</h1>
      <div className='flex flex-col items-start'>
      <p className='text-white italic text-sm'>
      <span className="italic">"</span>
          {"Learning is the only thing the mind never exhausts, never fears, and never regrets."}
          <span className="italic">"</span>
        </p>
        <p className='text-white italic text-sm'>Leonardo da Vinci</p>
      </div>
    </div>
  );
}

export default Header;

