export const experiances = [
  {
    _id: "6262624242cdfsddss",

    company: "Freelancer",
    title: "Full stack Dev. / Devops Eng.",
    period: "Dec 2022-present",
    description: `
        <div style="color: white;">
        <p style="margin-bottom: 10px;">In my role as a freelance Full Stack Developer, I actively contributed to various projects, refining my skills in both front-end and back-end development. Here are some key aspects of my role:</p>
        
        <h3 style="color: #eed13f;margin: 10px">Collaboration:</h3>
        <p>Worked closely with cross-functional teams, actively participating in gathering requirements, designing solutions, and ensuring successful project delivery.</p>
        
        <h3 style="color: #eed13f;margin: 10px">Optimization:</h3>
        <p>Led initiatives to optimize database performance, resulting in a significant improvement in application responsiveness, enhancing user experience, and overall system efficiency.</p>
        
        <h3 style="color: #eed13f;;margin: 10px">Testing Expertise:</h3>
        <p>Introduced and executed comprehensive testing strategies, including manual testing for UI/UX validation and API testing to ensure robust and secure backend functionality.</p>
        
        <h3 style="color: #eed13f;;margin: 10px">Cloud Skills:</h3>
        <p>Proficient in cloud computing, leveraging platforms such as AWS (Amazon Web Services) to deploy, manage, and scale applications in a cloud environment. Implemented scalable and reliable solutions, optimizing infrastructure for performance and cost efficiency.</p>
        
        <h3 style="color: #eed13f;;margin: 10px">DevOps Practices:</h3>
        <p style="margin-bottom: 20px;">Actively incorporated DevOps practices into the development lifecycle, including continuous integration, continuous deployment (CI/CD), and infrastructure as code (IaC). Implemented automation scripts for deployment pipelines, enhancing the efficiency of the development process.</p>
        
        <p style="margin-top: 10px;">My commitment to delivering high-quality software extends beyond development, incorporating a strong emphasis on testing, leveraging cloud technologies, and integrating DevOps practices to guarantee reliability, security, scalability, and streamlined development workflows across all aspects of the application. This multifaceted approach has consistently contributed to the success of projects and the overall growth of the development team.</p>
      </div>
          `,
  },
  {
    _id: "6262624536343dfsddss",
    company: "New Idea Machine",
    title: "Full stack dev.",
    period: "Aug,2022-Dec,2022",
    description: `<div style="color: white;">
        <p style="margin-bottom: 10px;">
          Contributed significantly to sprint cycles by designing and planning modules for seamless project integration within an agile framework.
        </p>
        
        <h3 style="color: #eed13f; margin: 10px">Collaboration:</h3>
        <p style="margin-bottom:5px">
          Worked closely with cross-functional teams, actively participating in gathering requirements, designing solutions, and ensuring successful project delivery.
        </p>
        <p style="margin-bottom:5px">
          Collaborated on the design and implementation of REST APIs, ensuring robust and secure backend functionality.
        </p>
        <p>
          Played a key role in testing REST APIs and meticulously documented findings to facilitate clear communication within the development team.
        </p>
        
        <h3 style="color: #eed13f; margin: 10px">Front-end optimization:</h3>
        <p>
          Developed frontend filtering capability using JavaScript and Styled Components, enhancing user interface functionality.
        </p>
        
        <h3 style="color: #eed13f; margin: 10px">Testing Expertise:</h3>
        <p>
          Introduced and executed comprehensive testing strategies, including manual testing for UI/UX validation and API testing to ensure robust and secure backend functionality.
        </p>
        
        <h3 style="color: #eed13f; margin: 10px">Back-end:</h3>
        <p>
          Modified and updated C# modules in line with user stories, ensuring code alignment with the main repository and maintaining code quality standards.
        </p>
        
        <p style="margin-top: 12px;">
   Engaging in daily sprints with a meticulous focus on agile methodology, I ensure alignment with user stories and project objectives in backend development. Actively participating in scrum meetings, I contribute insights to refine and prioritize user stories, while designing and implementing robust backend solutions that adhere to high standards of reliability, security, and scalability. Rigorously validating user stories to seamlessly align with project requirements, I implement comprehensive testing strategies, including manual testing for UI/UX validation and thorough API testing for backend functionality.
</p>

        
        
      </div>
      `,
  },
  {
    _id: "24536343dfsddss",
    company: "Equ Nile ICT consultancy",
    title: "Full stack dev.",
    period: "June 2016- Oct 2021",
    description: `<div style="color: white;">
    
<div style="color: white;">
    <p style="margin-bottom: 10px;">
        Equ Nile ICT Consultancy LTD stands as a versatile trading entity, bridging the realms of merchandise and software development with a keen focus on ERP software services. It's operations extend across East Africa, serving a diverse clientele with tailored solutions.
    </p>
    
    <p style="margin-bottom: 10px;">
        In my tenure at Equ Nile ICT Consultancy LTD, I spearheaded the development of various software solutions, with notable contributions to the creation of a comprehensive Hotel Management System. Additionally, I played a pivotal role in designing and structuring databases, ensuring seamless data management across projects and bolstering efficiency.
    </p>
    
    <p style="margin-bottom: 10px;">
        Utilizing advanced technologies such as Java Servlets and JDBC, I led initiatives to optimize server-side operations, significantly enhancing data retrieval speed and overall system performance. Furthermore, I provided extensive support by installing, configuring, and delivering training for the Hotel Management System at numerous properties, totaling over 30 installations.
    </p>
    
    <p style="margin-bottom: 10px;">
        My role also encompassed driving improvements in code structure and database queries, resulting in tangible enhancements to application performance and user experience. Collaborating closely with the team, I actively contributed to bug identification and resolution, ensuring the delivery of robust and reliable software solutions.
    </p>
    
    <p style="margin-bottom: 10px;">
        In addition to technical endeavors, I dedicated efforts to interface design, prioritizing user experience and system reliability. Notably, I conceptualized and implemented an internal support ticketing system, streamlining support operations and enabling effective tracking and management of key support metrics.
    </p>
</div>


  </div>
  `,
  },
  {
    _id: "2453634sddssxx",
    company: "Ministry of Health ",
    title: "Software developer",
    period: "Sep 2009-May 2016",
    description: `<div style="color: white;">
    
    <p style="margin-bottom: 10px;">
        The Ministry of Health in Eritrea is responsible for overseeing and managing healthcare services and policies in the country. Its primary focus includes promoting public health, coordinating medical services, and implementing healthcare programs to ensure the well-being of the population.
    </p>
    
    <p style="margin-bottom: 10px;">
        Spearheaded the development of mission-critical healthcare applications, ensuring compliance with regulatory standards and data security protocols.</br> Collaborated with cross-functional teams to gather requirements, design software solutions, and implement features to enhance healthcare systems.</br> Pioneered the transition from a paper-based to an IT-based Human Resources Management System, showcasing leadership in software development and digital transformation.
    </p>
    
    <p style="margin-bottom: 10px;">
        Led a team of developers in the end-to-end development and deployment of the HRMS, ensuring alignment with organizational goals and regulatory requirements.</br> Led the preparation of the Software Requirements Document (SRD) for 5+ projects, detailing project scope, objectives, and functional specifications to guide the development team. </br>Spearheaded the creation of user guidelines, facilitating seamless user adoption by providing clear instructions on system function and best practices. Additionally, developed and managed metadata structures.
    </p>
    
    <p style="margin-bottom: 10px;">
        Collaborated with cross-functional teams to gather requirements, aligning them with organizational goals and end-user needs, and conducted user training sessions to enhance system usability.
    </p>
</div>
  </div>
  `,
  },
];
