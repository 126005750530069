import React, { useState } from "react";
import SectionTitle from "../../components/SectionTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faDatabase } from "@fortawesome/free-solid-svg-icons";

import {
  faJs,
  faNodeJs,
  
  faJava,
  faGithub,
  faSelenium,
  faPostman,
  faNpm,
  faGitlab,
  faDebian,
  faTrello,
  faDocker,
  faReact,
  faJenkins,
  faSlack,
  faTerraform,
  faAnsible,
  faNginx,
  faAws,
  faPrometheus,
  faGripHorizontal,
  faChartLine,

} from "@fortawesome/free-brands-svg-icons";

function getSkillTitle(icon) {
  switch (icon) {
    case faNodeJs:
      return 'Node.js';
    case faJava:
      return 'Java';
    case faGithub:
      return 'GitHub';
    case faDocker:
      return 'Docker';
    case faNpm:
      return 'npm';
    case faGitlab:
      return 'GitLab';
    case faReact:
      return 'React';
    case faJenkins:
      return 'Jenkins';
    case faSlack:
      return 'Slack';
    case faDebian:
      return 'Debian';
    case faTrello:
      return 'Trello';
    case faAws:
      return 'AWS';
      case faDatabase:
        return 'SQL/NOSQL';
    default:
      return '';
  }
}

function About() {
  const [hoveredIcon, setHoveredIcon] = useState(null);
  const icons = [
    faNodeJs,
    faJava,
    faDatabase,
    faGithub,
    faDocker,
    faNpm,
    faGitlab,
    faReact,
    faJenkins,
    faSlack,
    faDebian,
    faTrello,
    faAws,
  ];

  
  return (
    <div>
      <SectionTitle title="About" />
      <div className="flex w-full item-center  sm:flex-col">
        <div className="h-[70vh] w-1/2 sm:w-full">
          <dotlottie-player
            src="https://lottie.host/ce56de02-1390-4abe-8e1f-1a0b27934c80/E3TSAa5J65.json"
            background="transparent"
            speed="1"
            loop
            autoplay
          ></dotlottie-player>
        </div>
        <div className="flex flex-col gap-5 w-1/2 sm:w-full">
          <p className="text-white m1-4">
            Hey there, I'm <span class="text-secondary">Amanuel Nigussie</span>.
            From the intricate world of Data Structures and Algorithms to the
            art of crafting robust Object-Oriented Programs and system
            architectures, I've always found joy in unraveling complex business
            challenges through software development. One of my notable
            achievements includes developing a distributed HRM application that
            seamlessly operated even without an internet connection—a project
            that stands as a testament to my passion for innovative solutions.
          </p>
          <p className="text-white m1-4">
            These days, my focus shifts between crafting Node.js and Java
            applications and delving into the realm of AWS cloud infrastructure.
            Juggling roles in DevOps engineering and Site Reliability
            Engineering (SRE), I bring a multifaceted approach to my work.
            Alongside software development, I actively engage in software
            testing to ensure the robustness and reliability of the solutions I
            create. This versatility allows me to navigate the complexities of
            both application development and the intricacies of cloud
            environments.
          </p>
          <p className="text-white m1-4">
            As a seasoned software developer with a strong background in
            business analysis, I've successfully led the design and
            implementation of hotel management software. Leveraging my business
            analysis skills, I thoroughly understood the unique requirements of
            the hospitality industry and seamlessly translated them into a
            tailored solution. My hands-on involvement extended to coding and
            deploying the software across over 20 properties, showcasing both
            technical proficiency and the ability to bridge the gap between
            business needs and effective technological solutions. Committed to
            creating impactful software, I bring efficiency and innovation to
            businesses through a blend of development expertise and business
            analysis acumen.
          </p>
        </div>
      </div>
      <div className="py-5">
        <h1 className="text-tertiary text-2xl py-2 ">
          Here are a few tech I've been working with recently :
        </h1>
        <div className="flex flex-wrap gap-4 text-secondary justify-between py-3">
        {icons.map((icon, index) => (
          <FontAwesomeIcon
            key={index}
            icon={icon}
            size="4x"
            title={getSkillTitle(icon)}
            className={`icon ${hoveredIcon === index ? "icon-hover" : ""}`}
            onMouseEnter={() => setHoveredIcon(index)}
            onMouseLeave={() => setHoveredIcon(null)}
          />
        ))}
        </div>
      </div>
    </div>
  );
}

export default About;
