import React from "react";
import SectionTitle from "../components/SectionTitle";

function Contact() {
  const user = {
    name: "Amanuel Nigussie",
    email: "amannov21@gmail.com",
    City: "Calgary",
    Country: "Canada",
    Tel: "+14037105253",
   
  };
  return (
    <div id="contact-section">
      <SectionTitle title="Let's connect" />
      <div className="">
      <div className="flex sm:flex-col items-center justify-between">
        <div className="flex flex-col gap-1">
          <p className="text-white">{"{"}</p>
          {Object.keys(user).map((key) => (
            <p className="ml-5">
              <span className="text-tertiary">{key} :</span>
              <span className="text-tertiary">{user[key]}</span>
            </p>
          ))}
          <p className="text-white">{"}"}</p>
        </div>
        <div className="h-[350px] ">
          <script
            src="https://unpkg.com/@dotlottie/player-component@latest/dist/dotlottie-player.mjs"
            type="module"
          ></script>

          <dotlottie-player
            src="https://lottie.host/9f7c56d8-e32e-4559-8b1d-ed440c95c356/EOfKs5ABJP.json"
            background="transparent"
            speed="1"
            
            loop
            autoplay
          ></dotlottie-player>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Contact;
