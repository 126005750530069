import React from "react";

function Intro() {
  return (
    <div className="h-[120vh] bg-primary flex flex-col items-start justify-center gap-8 py-10 ">
      <h1 className="text-white">Hi, I am</h1>
      <h1 className="text-7xl sm:text-3xl text-secondary font-semibold">
        Amanuel T. Nigussie
      </h1>

      <h1 className="text-5xl sm:text-3xl text-white font-semibold">
        Crafting code, infrastructure, and cloud.
      </h1>
      <p className="text-white w-2/3">
        I am an accomplished Full Stack Developer with a specialization in
        software development, encompassing a comprehensive skill set ranging
        from front-end to back-end technologies. As a freelancer based in
        Alberta, Canada, I leverage my expertise in software development, DevOps
        practices, and software testing to deliver bespoke and efficient
        solutions. My passion for creating seamless software experiences drives
        me to actively engage with the community, sharing insights and knowledge
        gained throughout my career through online teaching and collaboration.
      </p>
      <button className="border-2 border-tertiary text-tertiary px-10 py-3 rounded">
        Get started
      </button>
    </div>
  );
}

export default Intro;
